@font-face {
  font-family: 'Onest-Regular';
  src: url('./FontsSource/Onest-Regular.woff') format('woff'),
  url('./FontsSource/Onest-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Onest-Medium';
  src: url('./FontsSource/Onest-Medium.woff') format('woff'),
  url('./FontsSource/Onest-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Onest-Bold';
  src: url('./FontsSource/Onest-Bold.woff') format('woff'),
  url('./FontsSource/Onest-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Onest-ExBold';
  src: url('./FontsSource/Onest-Heavy.woff') format('woff'),
  url('./FontsSource/Onest-Heavy.ttf') format('truetype');
}
